import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=2f205d55&scoped=true&"
import script from "./Avatar.vue?vue&type=script&lang=js&"
export * from "./Avatar.vue?vue&type=script&lang=js&"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=2f205d55&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f205d55",
  null
  
)

export default component.exports